
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useGraph } from '@/composable/useGraph'
import { useRoute } from 'vue-router'
import { useGraphCirculation } from '@/composable/useGraphCirculation'
import { useApp } from '@/composable/useApp'

export default defineComponent({
  setup() {
    const grafCanvas = ref<HTMLCanvasElement>()
    const {
      timelineZoomMode,
      grafMode,
      init,
      model,
      setSelectedTrainNumber,
      setCanvasSize,
      showCirculations,
    } = useGraph()
    const {
      canvasHeight,

      setSelectedTrainNumber: setSelectedTrainNumberCirculation,
    } = useGraphCirculation()
    const rotue = useRoute()
    const { showHeader } = useApp()

    if (
      typeof rotue.params.trainId === 'string' &&
      rotue.params.trainId.length
    ) {
      model.value.trainNumber = rotue.params.trainId
    }

    if (
      typeof rotue.params.departureDate === 'string' &&
      rotue.params.departureDate.length
    ) {
      model.value.departureDate = rotue.params.departureDate
    }

    onMounted(() => {
      if (!grafCanvas.value) return
      init(grafCanvas.value)
    })

    watch(
      () => model.value,
      async (value) => {
        const shouldFetchCirculationData = await setSelectedTrainNumber(
          value.trainNumber,
          value.departureDate
        )
        if (shouldFetchCirculationData) {
          await setSelectedTrainNumberCirculation(
            value.trainNumber,
            value.departureDate
          )
        }
      },
      {
        immediate: true,
        deep: true,
      }
    )

    watch(
      () => [canvasHeight.value, showHeader.value, showCirculations],
      () => {
        setCanvasSize()
      }
    )

    const style = computed(() => {
      return {
        height: `calc(100vh - 178px -  ${
          showCirculations.value ? `${canvasHeight.value}px - 6px` : '4px'
        } + ${showHeader.value ? '0px' : '118px'})`,
      }
    })

    return {
      grafCanvas,
      grafMode,
      timelineZoomMode,
      style,
    }
  },
})
