
import { defineComponent, PropType } from 'vue'
import { AvikGraphTimetableItem } from '@/api/graph/getAvikGraphTimetable'
import { v4 as uuid } from 'uuid'
import useDialogPluginComponent from 'quasar/src/composables/use-dialog-plugin-component.js';
import { useGraphCirculation } from '@/composable/useGraphCirculation'

export default defineComponent({
  name: 'SelectGraphTrainModal',

  emits: [...useDialogPluginComponent.emits],

  props: {
    options: {
      type: Array as PropType<AvikGraphTimetableItem[]>,
      default: () => [],
    },
  },

  setup(props) {
    const rowsWithUuid = props.options.map((x) => {
      return { ...x, uuid: uuid() }
    })
    const { setSelectedTrainNumber } = useGraphCirculation()

    const { dialogRef, onDialogOK } = useDialogPluginComponent()

    const handleSelect = async (
      _evt: Event,
      selectedTrain: AvikGraphTimetableItem
    ) => {
      await setSelectedTrainNumber(
        selectedTrain.core,
        selectedTrain.departureDate
      )
      onDialogOK([selectedTrain])
    }

    const columns = [
      {
        label: 'Core',
        field: 'core',
        name: 'core',
        align: 'left',
        sortable: false,
      },
      {
        label: 'Sökande',
        field: 'owner',
        name: 'owner',
        align: 'left',
        sortable: false,
      },
      {
        label: 'Operatör',
        field: 'operator',
        name: 'operator',
        align: 'left',
        sortable: false,
      },
    ]

    return { columns, rowsWithUuid, handleSelect, dialogRef }
  },
})
