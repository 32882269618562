import { render } from "./GraphView.vue?vue&type=template&id=1b854153"
import script from "./GraphView.vue?vue&type=script&lang=ts"
export * from "./GraphView.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QToolbar,QSpace,QSeparator,QSelect,QBtnToggle});
