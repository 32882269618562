import { render } from "./SelectGraphTrainModal.vue?vue&type=template&id=33b0c128"
import script from "./SelectGraphTrainModal.vue?vue&type=script&lang=ts"
export * from "./SelectGraphTrainModal.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QTable from 'quasar/src/components/table/QTable.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QCard,QCardSection,QSeparator,QTable});
